/* Wrapper for the detail view */
.gatepass-detail-wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 10vh; /* Ensure the wrapper takes at least the full viewport height */
  padding: 15px; /* Reduced padding */
  box-sizing: border-box; /* Include padding in width calculation */
  background-color: #f5f5f5; /* Light background to differentiate from content */
}

/* Main container for gate pass details */
.gatepass-detail-container {
  max-width: 750px; /* Reduced max width */
  width: 100%; /* Make container responsive */
  padding: 15px; /* Reduced padding */
  background-color: #fff; /* White background for content */
  border-radius: 7.5px; /* Slightly smaller border radius */
  box-shadow: 0 0 7.5px rgba(0, 0, 0, 0.1); /* Reduced shadow size */
  overflow: auto; /* Add scroll if content overflows */
}

/* Title styling */
.title {
  text-align: center;
  color: #333;
  font-size: 13.5pt; /* Reduced font size */
  margin-bottom: 10px; /* Reduced margin */
}

/* Logo container */
.logo-container {
  text-align: center;
  margin-bottom: 15px; /* Reduced margin */
}

/* Company logo styling */
.company-logo {
  width: 112.5px; /* Reduced width */
  height: auto;
}

/* Group of details */
.detail-group {
  margin: 0 auto; /* Center horizontally */
  padding-bottom: 15px; /* Reduced padding */
  width: fit-content; /* Fit to content width */
  font-size: 10pt; /* Reduced font size */
  text-align: center; /* Center text inside the group */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Status section styling */
.status-section {
  display: flex;
  flex-direction: column; /* Stack icon above text */
  align-items: center; /* Center icon and text horizontally */
  margin-top: 15px; /* Reduced margin */
  padding: 10px; /* Reduced padding */
  font-size: 9pt; /* Reduced font size */
}

/* Status icon styling */
.status-icon {
  width: 75px; /* Reduced width */
  height: 75px; /* Reduced height */
  margin-bottom: 7.5px; /* Reduced margin */
}

/* Preview section styling */
.preview-section {
  padding: 15px; /* Reduced padding */
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px; /* Reduced border radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Layout for rows and columns */
.gatepass-row {
  display: flex;
  flex-direction: row; /* Ensure items are aligned side by side */
  flex-wrap: wrap; /* Ensure responsiveness by wrapping columns */
  justify-content: space-between;
  margin-bottom: 15px; /* Reduced margin */
}

.gatepass-column {
  flex: 1;
  min-width: 200px; /* Reduced minimum width */
  padding: 10px; /* Reduced padding */
  font-size: 10pt; /* Reduced font size */
  box-sizing: border-box; /* Include padding in width calculation */
}

.gatepass-column:not(:last-child) {
  margin-right: 7.5px; /* Reduced margin */
}

/* Table styling */
.gatepass-table1 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px; /* Reduced margin */
}

.gatepass-table1 th {
  border: 1px solid #494949;
  padding: 6px; /* Reduced padding */
  text-align: center;
  font-size: 10pt; /* Reduced font size */
  background-color: #e7e7e7;
}

.gatepass-table1 td {
  border: 1px solid #494949;
  padding: 6px; /* Reduced padding */
  text-align: center;
  font-size: 10pt; /* Reduced font size */
}

/* Style for action buttons */
.action-buttons {
  position: fixed; /* Fixed positioning for better responsiveness */
  top: 15px; /* Reduced top position */
  right: 15px; /* Reduced right position */
  display: flex;
  flex-direction: row; /* Ensure buttons are in a row */
  gap: 7.5px; /* Reduced gap */
  z-index: 1000; /* Ensure buttons are on top of other content */
  flex-wrap: wrap; /* Wrap buttons if they overflow */
}

.action-button {
  width: 37.5px; /* Reduced width */
  height: 37.5px; /* Reduced height */
  cursor: pointer;
}

.action-button:hover {
  opacity: 0.8;
}

/* Return button style */
.return-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* Remove default border */
  border-radius: 3.75px; /* Reduced border radius */
  padding: 6px 12px; /* Reduced padding */
  font-size: 9pt; /* Reduced font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background */
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Hover and focus states */
.return-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.return-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 3.75px #4CAF50; /* Reduced glow on focus */
}

/* Ensure the button doesn't overflow the container */
.action-buttons .return-button {
  width: auto; /* Allow button width to adjust based on content */
  height: auto; /* Adjust height to fit the content */
}

/* Loading state */
.loading {
  text-align: center;
  font-size: 13.5px; /* Reduced font size */
  color: #666;
}

/*############################################################################*/

/* Print-specific styles */
@media print {
  /* Hide action buttons and non-printable elements */
  .action-buttons,
  .back-button-container {
    display: none;
  }

  /* Ensure the main container is correctly positioned */
  .gatepass-detail-wrapper {
    position: static; /* Remove absolute positioning for print */
    margin: 0; /* Remove margin for print */
    padding: 0; /* Remove padding for print */
  }

  /* Main container for gate pass details */
  .gatepass-detail-container {
    display: block; /* Ensure the container is displayed */
    max-width: 750px; /* Reduced max width */
    width: 100%; /* Make container responsive */
    padding: 15px; /* Reduced padding */
    background-color: #fff; /* White background for content */
    border-radius: 0px; /* Slightly smaller border radius */
    box-shadow: none; /* Reduced shadow size */
    overflow: auto; /* Add scroll if content overflows */
  }

  /* Adjust column font size and padding for print */
  .gatepass-column {
    font-size: 10pt; /* Font size for columns on print */
    padding: 8px; /* Padding for better fit on print */
    background-color: #dad9d9;
  }

  /* Adjust table font size and padding for print */
  .gatepass-table1 th,
  .gatepass-table1 td {
    font-size: 10pt; /* Font size for table headers and cells on print */
    padding: 5px; /* Adjusted padding for fit on print */
  }

  /* Adjust title and other headings for print */
  .title {
    font-size: 16pt; /* Font size for title on print */
    margin-bottom: 10px; /* Margin for spacing */
  }

  .heading {
    font-size: 20pt; /* Font size for main heading on print */
    margin-bottom: 20px; /* Margin for spacing */
  }

  /* Ensure proper spacing between sections */
  .detail-group,
  .preview-section,
  .status-section {
    margin-bottom: .5px; /* Margin between sections */
  }

  /* Adjust logo size for print */
  .company-logo {
    width: 120px; /* Adjusted width for logo on print */
    height: auto; /* Maintain aspect ratio */
  }

  /* Hide non-essential elements for print */
  .status-icon {
    width: 80px; /* Adjust icon size for print */
    height: 80px; /* Adjust icon size for print */
  }

  /* Ensure no page breaks within important content */
  .gatepass-detail-container,
  .gatepass-row {
    page-break-inside: avoid;
  }

  /* Layout for rows and columns */
  .gatepass-row {
    display: flex;
    flex-wrap: wrap; /* Ensure responsiveness by wrapping columns */
    justify-content: space-between;
    margin-bottom: 15px; /* Reduced margin */
  }

  /* Footer (if any) adjustments */
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 10pt;
    border-top: 1px solid #ccc;
    padding: 10px;
  }
}


/*############################################################################*/
/* Back button container */
.back-button-container {
  margin: 15px 0; /* Reduced margin to create space at the top and bottom */
  text-align: left; /* Aligns the button to the left */
}

.back-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 7.5px 15px; /* Reduced padding */
  font-size: 12px; /* Reduced font size */
  cursor: pointer;
  border-radius: 3.75px; /* Reduced border radius */
  display: flex;
  align-items: center; /* Center align the icon and text */
}

.back-button i {
  margin-right: 6px; /* Reduced space between the icon and text */
}

.back-button:hover {
  background-color: #0056b3;
}
