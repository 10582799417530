/* src/components/Dashboard/Dashboard.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 202.5px; /* Further reduced by 10% */
  background-color: #2c3e50;
  color: white;
  padding: 16.2px; /* Further reduced by 10% */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  text-align: center;
  margin-bottom: 16.2px; /* Further reduced by 10% */
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 8.1px 0; /* Further reduced by 10% */
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 8.1px; /* Further reduced by 10% */
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar ul li a:hover {
  background-color: #34495e;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #ecf0f1;
  padding: 16.2px; /* Further reduced by 10% */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
  font-size: 19.44px; /* Further reduced by 10% */
}

.header button {
  padding: 8.1px 16.2px; /* Further reduced by 10% */
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.header button:hover {
  background-color: #c0392b;
}

.main-content {
  padding: 16.2px; /* Further reduced by 10% */
}

.stats {
  margin-bottom: 16.2px; /* Further reduced by 10% */
}

.stat-box {
  background-color: white;
  padding: 16.2px; /* Further reduced by 10% */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 8.1px; /* Further reduced by 10% */
}

.activities {
  background-color: white;
  padding: 16.2px; /* Further reduced by 10% */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.activities ul {
  list-style: none;
  padding: 0;
}

.activities ul li {
  margin: 8.1px 0; /* Further reduced by 10% */
  padding: 8.1px; /* Further reduced by 10% */
  border-bottom: 1px solid #f4f4f9;
}

.activities ul li:last-child {
  border-bottom: none;
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .header h1 {
    font-size: 16.2px; /* Further reduced font size by 10% */
  }

  .header button {
    padding: 6.48px 12.96px; /* Further reduced padding by 10% */
  }

  .sidebar {
    width: 162px; /* Further reduced sidebar width by 10% */
  }

  .sidebar h2 {
    font-size: 14.58px; /* Further reduced font size by 10% */
  }

  .main-content {
    padding: 12.15px; /* Further reduced main content padding by 10% */
  }

  .stat-box, .activities {
    padding: 12.15px; /* Further reduced box padding by 10% */
  }
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column; /* Stack sidebar and main content vertically */
  }

  .sidebar {
    width: 100%; /* Sidebar takes full width */
    padding: 12.15px; /* Further reduced sidebar padding by 10% */
  }

  .main-content {
    padding: 8.1px; /* Further reduced main content padding by 10% */
  }

  .header h1 {
    font-size: 14.58px; /* Further reduced font size by 10% */
  }

  .header button {
    width: auto;
    padding: 6.48px 9.72px; /* Further reduced button padding by 10% */
  }

  .stat-box, .activities {
    padding: 8.1px; /* Further reduced box padding by 10% */
  }

  .sidebar ul li a {
    padding: 6.48px; /* Further reduced link padding by 10% */
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 12.96px; /* Further reduced font size by 10% */
  }

  .header button {
    padding: 4.86px 8.1px; /* Further reduced button padding by 10% */
  }

  .sidebar {
    padding: 8.1px; /* Further reduced sidebar padding by 10% */
  }

  .sidebar ul li a {
    padding: 4.86px; /* Further reduced link padding by 10% */
  }

  .main-content {
    padding: 6.48px; /* Further reduced main content padding by 10% */
  }

  .stat-box, .activities {
    padding: 6.48px; /* Further reduced box padding by 10% */
  }
}
