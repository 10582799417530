/* Container for the Gate Passes component */
.gatepasses-container {
  max-width: 65%; /* Reduce width to fit better */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Title style */
h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem; /* Responsive font size */
  color: #333;
}

/* Search input style */
.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1rem; /* Responsive font size */
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Wrapper for the table */
.table-wrapper {
  max-height: 400px; /* Adjust as needed to fit your design */
  overflow-y: auto;
}

/* Table style */
.gatepasses-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Table header and cells */
.gatepasses-table th,
.gatepasses-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Sticky header styles */
.gatepasses-table thead {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: #f4f4f4; /* Ensure this is different from the table rows */
  z-index: 2; /* Ensure the header is above the table rows */
}

/* Table header styles */
.gatepasses-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

/* Style for the last row in the table */
.gatepasses-table tr:last-child td {
  border-bottom: none;
}

/* Alternate row coloring */
.gatepasses-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Row hover style */
.gatepasses-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Button style */
.back-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 80px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Responsive font size */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.back-button::before {
  content: '\2190'; /* Left arrow Unicode character */
  font-size: 1.2rem; /* Responsive font size */
  margin-right: 8px; /* Space between arrow and text */
}

.back-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.back-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.5);
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .gatepasses-container {
    max-width: 90%; /* Fit container better on smaller screens */
  }

  .back-button {
    width: 70px; /* Adjust size for smaller screens */
    height: 35px; /* Adjust size */
    font-size: 0.9rem; /* Adjust font size */
  }

  .search-input {
    font-size: 0.9rem; /* Adjust font size */
  }

  .gatepasses-table th, .gatepasses-table td {
    padding: 6px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .gatepasses-container {
    max-width: 95%; /* Fit container even better on very small screens */
  }

  .back-button {
    width: 60px; /* Adjust size for very small screens */
    height: 30px; /* Adjust size */
    font-size: 0.8rem; /* Adjust font size */
  }

  .search-input {
    font-size: 0.8rem; /* Adjust font size */
  }

  .gatepasses-table th, .gatepasses-table td {
    padding: 4px; /* Adjust padding for very small screens */
  }
}
