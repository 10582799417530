/* Login Container Styling */
.login-container {
  width: 300px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Heading Styling */
h2 {
  text-align: center;
}

/* Input Group Styling */
.input-group {
  margin-bottom: 15px;
}

/* Text Input Styling */
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button Styling */
button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Button Hover Effect */
button:hover {
  background-color: #0056b3;
}

/* Error Message Styling */
.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* Signup Link Styling */
.signup-link {
  text-align: center; /* Center align the signup link */
}

.signup-link a {
  color: #007bff; /* Change link color */
  text-decoration: none; /* Remove default underline */
}

.signup-link a:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Responsive Styles */

/* Tablet and Smaller Devices */
@media (max-width: 768px) {
  .login-container {
    width: 90%;
    margin: 50px auto;
    padding: 15px;
  }

  h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  input[type="text"],
  input[type="password"] {
    padding: 8px; /* Adjust padding for inputs */
  }

  button {
    padding: 8px; /* Adjust padding for buttons */
  }
}

/* Mobile Devices */
@media (max-width: 480px) {
  .login-container {
    width: 100%;
    margin: 40px auto;
    padding: 10px;
  }

  h2 {
    font-size: 18px; /* Adjust font size for even smaller screens */
  }

  input[type="text"],
  input[type="password"] {
    padding: 6px; /* Further adjust padding for inputs */
  }

  button {
    padding: 6px; /* Further adjust padding for buttons */
  }
}
