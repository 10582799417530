/* SignUp Container Styling */
.signup-container {
  width: 100%;
  max-width: 320px; /* Reduced max-width for smaller form */
  margin: 60px auto; /* Adjusted margin to fit content better */
  padding: 15px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Center Content */
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Heading Styling */
h2 {
  text-align: center;
  font-size: 20px; /* Reduced font size */
  margin-bottom: 15px; /* Reduced margin */
}

/* Form Group Styling */
.form-group {
  width: 100%;
  margin-bottom: 12px; /* Reduced margin */
}

/* Input Field Styling */
input[type="text"],
input[type="email"],
input[type="password"],
select {
  width: calc(100% - 20px); /* Adjusted width */
  padding: 8px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Reduced font size */
}

/* Error Field Styling */
input.error, select.error {
  border-color: red;
}

/* Error Message Styling */
.error-message {
  color: red;
  font-size: 12px; /* Reduced font size */
  margin-top: 5px;
  text-align: left;
}

/* Button Styling */
button {
  width: 100%;
  padding: 10px; /* Reduced padding */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Reduced font size */
}

/* Button Hover Effect */
button:hover {
  background-color: #0056b3;
}

/* Success Message Styling */
.success-message {
  text-align: center;
  font-size: 16px; /* Reduced font size */
  color: green;
}

.success-icon {
  font-size: 30px; /* Reduced icon size */
  margin-right: 8px;
}

.success-message p {
  font-size: 18px; /* Reduced font size */
}

/* Back to Sign In Link Styling */
.back-to-signin {
  margin-top: 15px; /* Reduced margin */
}

.back-to-signin a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.back-to-signin a:hover {
  text-decoration: underline;
}

/* Back Button Styling */
.back-button-container {
  margin-top: 8px; /* Reduced margin */
  text-align: center;
}

.back-button {
  padding: 8px 16px; /* Reduced padding */
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Reduced font size */
}

.back-button i {
  margin-right: 6px;
}

.back-button:hover {
  background-color: #5a6268;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    margin: 40px auto; /* Adjusted margin */
    padding: 10px; /* Reduced padding */
  }

  h2 {
    font-size: 18px; /* Adjusted font size */
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    font-size: 12px; /* Adjusted font size */
  }

  button {
    padding: 8px; /* Reduced padding */
    font-size: 12px; /* Adjusted font size */
  }
}

@media (max-width: 576px) {
  .signup-container {
    margin: 20px auto; /* Adjusted margin */
    padding: 8px; /* Reduced padding */
  }

  h2 {
    font-size: 16px; /* Adjusted font size */
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    font-size: 10px; /* Adjusted font size */
  }

  button {
    padding: 6px; /* Reduced padding */
    font-size: 10px; /* Adjusted font size */
  }

  .success-message p {
    font-size: 14px; /* Adjusted font size */
  }
}
