/* Base styles */
.gatepass-form-container {
  max-width: 63%; /* Further reduced width for better fit */
  margin: 20px auto;
  padding: 14px; /* Further reduced padding */
  border: 1px solid #ccc;
  border-radius: 6px; /* Further reduced border-radius */
  background-color: #f9f9f9;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); /* Further reduced shadow */
  font-family: Arial, sans-serif;
}

.gatepass-form-container h2, .gatepass-form-container h3 {
  text-align: center;
  margin-bottom: 6px; /* Further reduced margin-bottom */
  font-size: 18px; /* Further reduced font-size */
  color: #333;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px; /* Further reduced margin-bottom */
}

.logo-container img {
  width: 64px; /* Further reduced width */
  height: auto;
}

.company-logo {
  width: 108px; /* Further reduced width */
  height: auto;
}

.date-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px; /* Further reduced margin-bottom */
}

.date-group {
  display: flex;
  align-items: center;
}

.date-group label {
  margin-right: 6px; /* Further reduced margin-right */
}

.date-group input {
  padding: 5px; /* Further reduced padding */
  border: 1px solid #ccc;
  border-radius: 3px; /* Further reduced border-radius */
  width: auto;
}

.gatepass-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 14px; /* Further reduced margin-bottom */
}

.gatepass-column {
  flex: 1;
  margin: 14px; /* Further reduced margin */
  text-align: left;
}

.gatepass-input-group {
  margin-bottom: 10px; /* Further reduced margin-bottom */
}

.gatepass-input {
  width: calc(100% - 16px); /* Further adjusted width */
  padding: 7px; /* Further reduced padding */
  border: 1px solid #ccc;
  border-radius: 3px; /* Further reduced border-radius */
  font-size: 13px; /* Further reduced font-size */
}

.gatepass-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 14px; /* Further reduced margin-bottom */
}

.gatepass-table th,
.gatepass-table td {
  border: 1px solid #ccc;
  padding: 7px; /* Further reduced padding */
  text-align: center;
}

.gatepass-table th {
  background-color: #e7e7e7;
}

.gatepass-table td input {
  width: calc(100% - 8px); /* Further adjusted width */
  padding: 3px; /* Further reduced padding */
  border: 1px solid #ccc;
  border-radius: 2px; /* Further reduced border-radius */
  font-size: 11px; /* Further reduced font-size */
}

.add-item-btn, .delete-item-btn, form button[type="submit"], .preview-section button {
  padding: 7px 10px; /* Further reduced padding */
  border: none;
  border-radius: 3px; /* Further reduced border-radius */
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
  margin-right: 6px; /* Further reduced margin-right */
}

/* Add Item button */
.add-item-btn {
  background-color: #28a745;
}

.add-item-btn:hover {
  background-color: #218838;
}

/* Remove Item button */
.delete-item-btn {
  background-color: #dc3545;
  padding: 5px 9px; /* Further reduced padding */
  font-size: 11px; /* Further reduced font-size */
}

.delete-item-btn:hover {
  background-color: #c82333;
}

form button[type="submit"] {
  background-color: #007bff;
  margin-top: 14px; /* Further reduced margin-top */
}

/* Submit button */
form button[type="submit"]:hover {
  background-color: #0056b3;
}

.preview-section {
  padding: 14px; /* Further reduced padding */
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 6px; /* Further reduced border-radius */
  margin-bottom: 14px; /* Further reduced margin-bottom */
}

.gatepass-row .gatepass-column p {
  margin: 0 0 6px; /* Further reduced margin-bottom */
}

.edit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 7px 14px; /* Further reduced padding */
  margin-right: 6px; /* Further reduced margin-right */
  border-radius: 3px; /* Further reduced border-radius */
  cursor: pointer;
}

.submit-button {
  background-color: #28a745;
  margin-top: 14px; /* Further reduced margin-top */
}

/* Submit button */
.submit-button:hover {
  background-color: #218838;
}

/* Back button container */
.back-button-container {
  margin: 14px 0; /* Further reduced margin */
  text-align: left;
}

.back-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 7px 14px; /* Further reduced padding */
  font-size: 13px; /* Further reduced font-size */
  cursor: pointer;
  border-radius: 3px; /* Further reduced border-radius */
  display: flex;
  align-items: center;
}

.back-button i {
  margin-right: 5px; /* Further reduced margin-right */
}

.back-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .date-row, .gatepass-row {
    flex-direction: column;
  }

  .date-group, .gatepass-column {
    margin: 0;
  }

  .gatepass-column {
    margin-bottom: 10px; /* Further reduced margin-bottom */
  }

  .gatepass-input {
    font-size: 11px; /* Further reduced font-size */
  }

  .gatepass-table th, .gatepass-table td {
    font-size: 11px; /* Further reduced font-size */
  }

  .add-item-btn, .delete-item-btn, form button[type="submit"], .preview-section button {
    padding: 5px 9px; /* Further reduced padding */
    font-size: 11px; /* Further reduced font-size */
  }

  .logo-container img, .company-logo {
    width: 54px; /* Further reduced width */
  }

  .back-button {
    font-size: 11px; /* Further reduced font-size */
    padding: 5px 11px; /* Further reduced padding */
  }

  .edit-button, .submit-button {
    font-size: 11px; /* Further reduced font-size */
    padding: 5px 11px; /* Further reduced padding */
  }
}

@media (max-width: 480px) {
  .gatepass-form-container {
    padding: 6px; /* Further reduced padding */
    margin: 6px; /* Further reduced margin */
  }

  .gatepass-form-container h2, .gatepass-form-container h3 {
    font-size: 14px; /* Further reduced font-size */
  }

  .logo-container img, .company-logo {
    width: 45px; /* Further reduced width */
  }

  .add-item-btn, .delete-item-btn, form button[type="submit"], .preview-section button {
    padding: 3px 7px; /* Further reduced padding */
    font-size: 9px; /* Further reduced font-size */
  }

  .back-button, .edit-button, .submit-button {
    font-size: 9px; /* Further reduced font-size */
    padding: 3px 7px; /* Further reduced padding */
  }

  .date-group input, .gatepass-input {
    font-size: 9px; /* Further reduced font-size */
  }

  .gatepass-table th, .gatepass-table td {
    font-size: 9px; /* Further reduced font-size */
    padding: 3px; /* Further reduced padding */
  }
}
